import React, { useEffect, useState } from "react";
import "../properties/properties.css";
import { FaCheck } from "react-icons/fa6";
import { Link } from "react-router-dom";
import prop1 from "../../../../assets/eleraky/prop1.gif";
import prop2 from "../../../../assets/eleraky/prop2.gif";
import prop3 from "../../../../assets/eleraky/prop4.gif";
export const Properties = () => {
  return (
    <>
      <div className="props">
        <div data-aos="fade-up" data-aos-duration="1000" className="prop">
          <img src="" alt="" srcSet={prop1} />
          <div className="text">
            <h1>شرح منهج الاحياء كامل بالتفصيل</h1>
            <p>
              هنشرحلك المنهج كامل بالتفصيل و هتقدر تعيد المحاضرة عشان تفهم
              الدروس اكتر
            </p>
          </div>
          <FaCheck className="pr-icon" />
        </div>
        <div data-aos="fade-up" data-aos-duration="1000" className="prop">
          <img src="" alt="" srcSet={prop2} />
          <div className="text">
            <h1>شرح المنهج بالتفصيل</h1>
            <p>امتحانات تفاعلية مستمرة عشان تعرف مستواك الدراسي</p>
          </div>
          <FaCheck className="pr-icon" />
        </div>
        <div data-aos="fade-up" data-aos-duration="1000" className="prop">
          <img src="" alt="" srcSet={prop3} />
          <div className="text">
            <h1>امتحانات الكترونية</h1>
            <p>
              هنحل واجبات بعد كل محاضرة عشان نفهم اكتر و نتدرب على الامتحانات
            </p>
          </div>
          <FaCheck className="pr-icon" />
        </div>
      </div>
    </>
  );
};
