import React from "react";
import lightLogo from "../assets/eleraky/ho-img.png";
import companyLogo from "../assets/company-logo.png";

export default function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="contact-section">
            <div className="footer-logo">
              <img className="w-100 h-100" alt="" src={lightLogo} />
            </div>
            <h4 className="text-center">تواصل معنا عبر</h4>
            <div className="row py-2 w-100 ">
              {/* <div className='email col-lg-3 col-12 text-start'>
                        AhmedFadl@gmail.com
                        mahmoud-elgharabawy.com
                        <i className="fa-regular fa-envelope"></i>
                    </div> */}
              <div className="col-lg-3 col-12 text-end">
                01070611677
                <i className="fa-solid fa-phone-flip"></i>
              </div>
              <div className="col-lg-3 col-12 text-end">
                01070624455
                <i className="fa-solid fa-phone-flip"></i>
              </div>
            </div>
            <div className="footer-social-media py-1">
              <a
                href="https://youtu.be/9yUSmMauhgA?si=BhUyM4WpACTYuqeG  "
                target="_blank"
              >
                <i className="fa-brands fa-youtube"></i>
              </a>
            </div>
          </div>
          <div className="copyright">
            <div className="copyright-logo">
              <img className="w-100 h-100" src={companyLogo} alt="" />
            </div>
            <p>حقوق النشر © 2024 لشركة Easy Tech . كل الحقوق محفوظة.</p>
          </div>
        </div>
      </footer>
    </>
  );
}
